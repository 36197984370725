@import "./theme-variables.scss";

$changeProductDivisionPaddingBottom: 20px;
$changeProductDivisionTitleFontSize: 13px;
$changeProductDivisionTitleLetterSpacing: 2.17px;
$changeProductDivisionTitlePaddingBottom: 2px;
$changeProductDivisionTitleHeight: 15px;

$changeProductModalThumbnailContainerWidth: 96px;
$changeProductModalThumbnailContainerHeight: 155px;
$changeProductModalThumbnailContainerMarginRight: 8px;
$changeProductModalThumbnailContainerMarginBottom: 8px;
$changeProductModalThumbnailLineFontSize: 12px;
$changeProductModalThumbnailLineLetterSpacing: 0.72px;
$changeProductModalThumbnailLineLineHeight: 17.33px;
$changeProductModalThumbnailImageMarginBottom: 5px;

.change-product-division {
    width: 100%;
    padding-bottom: $changeProductDivisionPaddingBottom;
}

.change-product-division-title {
    font-family: 'FeniceStd', sans-serif;
    font-size: $changeProductDivisionTitleFontSize;
    letter-spacing: $changeProductDivisionTitleLetterSpacing;
    color: var(--colour-primary);
    margin-bottom: $changeProductDivisionTitlePaddingBottom;
    text-transform: uppercase;
    
    height: $changeProductDivisionTitleHeight;
    background-color: var(--colour-secondary);
}

.change-product-division-products {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.change-product-modal-thumbnail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: $changeProductModalThumbnailContainerWidth;
    height: $changeProductModalThumbnailContainerHeight;
    margin-bottom: $changeProductModalThumbnailContainerMarginBottom;
    position: relative;
    cursor: pointer;
}

.change-product-modal-thumbnail-container:nth-child(2n+1) {
    margin-right: $changeProductModalThumbnailContainerMarginRight;
}

.change-product-modal-thumbnail-image-container {
    height: $changeProductModalThumbnailContainerWidth;
    width: $changeProductModalThumbnailContainerWidth;
    margin-bottom: $changeProductModalThumbnailImageMarginBottom;
}

.change-product-modal-thumbnail-image {
    width: 100%;
    height: 100%;

    background-color: var(--colour-secondary);
}

.change-product-modal-thumbnail-line {
    width: $changeProductModalThumbnailContainerWidth;
    font-family: "FoundersGrotesk", sans-serif;
    font-size: $changeProductModalThumbnailLineFontSize;
    letter-spacing: $changeProductModalThumbnailLineLetterSpacing;
    line-height: $changeProductModalThumbnailLineLineHeight;
    text-align: center;

    background-color: var(--colour-secondary);
    height: $changeProductModalThumbnailLineLineHeight;
}

@media screen and (min-width: 1080px) {
    .change-product-division {
        padding-bottom: ($changeProductDivisionPaddingBottom * 1.5);
    }

    .change-product-division-title {
        font-size: ($changeProductDivisionTitleFontSize * 1.5);
        letter-spacing: ($changeProductDivisionTitleLetterSpacing * 1.5);
        padding-bottom: ($changeProductDivisionTitlePaddingBottom * 1.5);
        height: ($changeProductDivisionTitleHeight * 1.5);
    }

    .change-product-modal-thumbnail-container {
        width: ($changeProductModalThumbnailContainerWidth * 1.5);
        height: ($changeProductModalThumbnailContainerHeight * 1.5);
        margin-bottom: ($changeProductModalThumbnailContainerMarginBottom * 1.5);
        margin-left: ($changeProductModalThumbnailContainerMarginRight * 1.5);
        margin-right: 0;
    }

    .change-product-modal-thumbnail-container:nth-child(2n+1) {
        margin-right: 0;
    }

    .change-product-modal-thumbnail-container:nth-child(3n-2) {
        margin-left: 0;
        margin-right: 0;
    }

    .change-product-modal-thumbnail-image-container {
        height: ($changeProductModalThumbnailContainerWidth * 1.5);
        width: ($changeProductModalThumbnailContainerWidth * 1.5);
        margin-bottom: ($changeProductModalThumbnailImageMarginBottom * 1.5);
    }

    .change-product-modal-thumbnail-line {
        width: ($changeProductModalThumbnailContainerWidth * 1.5);
        font-size: ($changeProductModalThumbnailLineFontSize * 1.5);
        letter-spacing: ($changeProductModalThumbnailLineLetterSpacing * 1.5);
        line-height: ($changeProductModalThumbnailLineLineHeight * 1.5);
        height: ($changeProductModalThumbnailLineLineHeight * 1.5);
    }
}
