@import "./theme-variables.scss";
@import "./mixins.scss";

.loading {
    background-size: 600px;
    min-height: 20px;
    @include animate(LOADER 1s infinite);
    background: linear-gradient(to right, var(--load-from), var(--load-to), var(--load-to), var(--load-from)), var(--load-to);
}

.loadingROCTabs {
    background-size: 600px;
    min-height: 72px;
    @include animate(LOADER 1s infinite);
    background: linear-gradient(to right, var(--load-from), var(--load-to), var(--load-to), var(--load-from)), var(--load-to);
}
@keyframes LOADER {
    0% {
        background-position: -100px + 50;
        @include transform(scale(1));
    }

    100% {
        background-position: 140px + 50;
        @include transform(scale(1));
    }
}
.loadingBlue {
    background-size: 600px;
    min-height: 20px;
    @include animate(LOADERBLUE 1s infinite);
    background: linear-gradient(to right, var(--load-from)-navy, var(--load-to-navy), var(--load-to-navy), var(--load-from)-navy), var(--load-to);
}

@keyframes LOADERBLUE {
    0% {
        background-position: -100px + 50;
        @include transform(scale(1));
    }

    100% {
        background-position: 1080px;
        @include transform(scale(1));
    }
}

.loadingHomepageBanner {
    background-position: 0;
    @include animate(BannerLoader 1s infinite);
    background: linear-gradient(to right, var(--load-from), var(--load-to-banner), var(--load-to-banner), var(--load-from)), var(--load-to-banner);
}

@keyframes BannerLoader {
    to {
        background-position: 1080px;
    }
}

.loadingView {
    background-size: 600px;
    min-height: 20px;
    @include animate(LOADERVIEW 1s infinite);
    background: linear-gradient(to right, var(--load-from), var(--load-to), var(--load-to), var(--load-from)), var(--load-to);
}

@keyframes LOADERVIEW {
    0% {
        background-position: -100px;
        @include transform(scale(1));
    }

    100% {
        background-position: 100% + 20;
        @include transform(scale(1));
    }
}

.loadingViewName {
    background-size: 600px;
    min-height: 20px;
    @include animate(LOADERVIEWNAME 1s infinite);
    background: linear-gradient(to right, var(--load-from), var(--load-to), var(--load-to), var(--load-from)), var(--load-to);
}

@keyframes LOADERVIEWNAME {
    0% {
        background-position: -50px;
        @include transform(scale(1));
    }

    100% {
        background-position: 100% + 20;
        @include transform(scale(1));
    }
}

.loadingSwatch {
    background-size: 600px;
    min-height: 20px;
    @include animate(LOADERSWATCH 1s infinite);
    background: linear-gradient(to right, var(--load-from), var(--load-to), var(--load-to), var(--load-from)), var(--load-to);
}

@keyframes LOADERSWATCH {
    0% {
        background-position: -20px;
        @include transform(scale(1));
    }

    100% {
        background-position: 100% + 20;
        @include transform(scale(1));
    }
}

.loadingOption {
    background-size: 600px;
    min-height: 20px;
    @include animate(LOADEROPTION 1s infinite);
    background: linear-gradient(to right, var(--load-from), var(--load-to), var(--load-to), var(--load-from)), var(--load-to);
}

@keyframes LOADEROPTION {
    0% {
        background-position: -50px;
        @include transform(scale(1));
    }

    100% {
        background-position: 100% + 20;
        @include transform(scale(1));
    }
}

@media only screen and (max-width: 1024px) {
    @keyframes BannerLoader {
        to {
            background-position: 360px;
        }
    }
    @keyframes LOADERBLUE {
        0% {
            background-position: -50px + 50;
            @include transform(scale(1));
        }

        50% {
            @include transform(scale(1.005));
        }

        100% {
            background-position: 500px;
            @include transform(scale(1));
        }
    }

    .loadingROCTabs {
        background-size: 600px;
        min-height: 48px;
        @include animate(LOADER 1s infinite);
        background: linear-gradient(to right, var(--load-from), var(--load-to), var(--load-to), var(--load-from)), var(--load-to);
    }

    @keyframes LOADERSWATCH {
        0% {
            background-position: -20px;
            @include transform(scale(1));
        }

        100% {
            background-position: 100% + 20;
            @include transform(scale(1));
        }
    }

    @keyframes LOADEROPTION {
        0% {
            background-position: -30px;
        }

        100% {
            background-position: 100%;
        }
    }
}

.loadingColourBar {
    min-height: 24px;
    width: 24px;
    @include animate(LOADER 1s infinite);
    background: linear-gradient(to right, var(--load-from), var(--load-to), var(--load-to), var(--load-from)), var(--load-to);
}

@keyframes ColourBarLoader {
    0% {
        background-position: -50px;
        @include transform(scale(1));
    }

    100% {
        background-position: 140px + 50;
        @include transform(scale(1));
    }
}
