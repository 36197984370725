@import "./theme-variables.scss";
@import "./fonts";

.errorContainer {
    width: 100%;
    height: 100%;
    font-family: "FeniceStd";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 24px;
    padding-top: 15%;
    
}
.errorPagePonyImage {
    width: 100%;
}
.errorContainer > h1,
.errorContainer > h2 {
    margin: 0;
}
.errorContainer > h1 {
    font-size: 48px;
}
.errorContainer > h2 {
    font-family: "FeniceStd";
    font-weight: 400;
    font-size: 36px;
}

.errorContainer > div {
    margin-top: 10px;     
}
.errorContainer a {
    text-decoration: underline;
    cursor: pointer;
}

@media only screen and (max-width:1024px) {
    .errorContainer {       
        align-items: center;
        justify-content: center;
        padding: 16px;
    }
    .errorPagePonyImage {
        width: 90%;
    }
    .errorContainer > h1 {
        font-size: 36px;
    }
    .errorContainer > h2 {
        font-size: 24px;
    }
    .errorContainer > div {
        margin-top: 5px;
        margin-bottom: 36px;    
    }
}

@media only screen and (orientation: landscape){
    .errorContainer {       
        padding-top:0;
        justify-content: center;
        align-items: center;
    }
    .errorPagePonyImage {
        width: unset;
        height:60%;
    }
}
