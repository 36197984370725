@font-face {
    font-family: "FeniceStd";
    src: url("/assets/fonts/FeniceStd/FeniceStd_Regular_0.otf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "FoundersGrotesk";
    src: url("/assets/fonts/FoundersGrotesk/FoundersGroteskText-Regular.otf");
}
