$changeProductModalThumbnailContainerWidth: 96px;
$changeProductModalThumbnailContainerHeight: 165px;
$changeProductModalThumbnailContainerMarginRight: 8px;
$changeProductModalThumbnailContainerMarginBottom: 8px;
$changeProductModalThumbnailLineFontSize: 11px;
$changeProductModalThumbnailLineLetterSpacing: 0.72px;
$changeProductModalThumbnailLineLineHeight: 17.33px;
$changeProductModalThumbnailImageMarginBottom: 5px;

.change-product-modal-thumbnail-wrapper {
    width: $changeProductModalThumbnailContainerWidth;
    height: $changeProductModalThumbnailContainerHeight;
    margin-bottom: $changeProductModalThumbnailContainerMarginBottom;
    position: relative;
}

.change-product-modal-thumbnail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: $changeProductModalThumbnailContainerWidth;
    height: $changeProductModalThumbnailContainerHeight;
    margin-bottom: $changeProductModalThumbnailContainerMarginBottom;
    position: relative;
    cursor: pointer;
}

.change-product-modal-thumbnail-wrapper:nth-child(2n+1) {
    margin-right: $changeProductModalThumbnailContainerMarginRight;
}

.change-product-modal-thumbnail-image-container {
    height: $changeProductModalThumbnailContainerWidth;
    width: $changeProductModalThumbnailContainerWidth;
    margin-bottom: $changeProductModalThumbnailImageMarginBottom;
}

.change-product-modal-thumbnail-image {
    width: 100%;
    height: 100%;
}

.change-product-modal-thumbnail-line {
    width: $changeProductModalThumbnailContainerWidth;
    font-family: "FoundersGrotesk", sans-serif;
    font-size: $changeProductModalThumbnailLineFontSize;
    letter-spacing: $changeProductModalThumbnailLineLetterSpacing;
    line-height: $changeProductModalThumbnailLineLineHeight;
    text-align: center;
}

@media screen and (min-width: 1080px) {
   .change-product-modal-thumbnail-wrapper {
        width: ($changeProductModalThumbnailContainerWidth * 1.5);
        height: ($changeProductModalThumbnailContainerHeight * 1.5);
        margin-bottom: ($changeProductModalThumbnailContainerMarginBottom * 1.5);
    }
   
    .change-product-modal-thumbnail-container {
        width: ($changeProductModalThumbnailContainerWidth * 1.5);
        height: ($changeProductModalThumbnailContainerHeight * 1.5);
        margin-bottom: ($changeProductModalThumbnailContainerMarginBottom * 1.5);
        margin-left: ($changeProductModalThumbnailContainerMarginRight * 1.5);
        margin-right: 0;
    }

    .change-product-modal-thumbnail-container:nth-child(2n+1) {
        margin-right: 0;
    }

    .change-product-modal-thumbnail-container:nth-child(3n-2) {
        margin-left: 0;
        margin-right: 0;
    }

    .change-product-modal-thumbnail-image-container {
        height: ($changeProductModalThumbnailContainerWidth * 1.5);
        width: ($changeProductModalThumbnailContainerWidth * 1.5);
        margin-bottom: ($changeProductModalThumbnailImageMarginBottom * 1.5);
    }

    .change-product-modal-thumbnail-line {
        width: ($changeProductModalThumbnailContainerWidth * 1.5);
        font-size: ($changeProductModalThumbnailLineFontSize * 1.5);
        letter-spacing: ($changeProductModalThumbnailLineLetterSpacing * 1.5);
        line-height: ($changeProductModalThumbnailLineLineHeight * 1.5);
    }
}