@import "./theme-variables";

$navbarCartItemsCounterSize: 14px;
$navbarCartItemsCounterFontSize: 10px;

.navbar-cart-items-counter {
    height: $navbarCartItemsCounterSize;
    width: $navbarCartItemsCounterSize;
    position: absolute;
    top: 2px;
    right: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "FoundersGrotesk", sans-serif;
    font-size: $navbarCartItemsCounterFontSize;
    background-color: var(--text-colour-navbar);
    color: var(--navbar-colour);
    border-radius: 50%;
    outline: none;
}

.navbar-cart-items-counter-text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@media screen and (min-width: 1080px) {
    .navbar-cart-items-counter {
        height: ($navbarCartItemsCounterSize * 1.5);
        width: ($navbarCartItemsCounterSize * 1.5);
        font-size: ($navbarCartItemsCounterFontSize * 1.5);
    }
}