@import "./themes.scss";
@import './theme-variables';
@import './fonts';

body,
html,
#root {
    width: 100%;
    height: 100%;    
    margin: 0 auto;
    padding: 0;
    max-width: $maximum-page-width;   
    background-color: var(--colour-secondary);
}
.page {
    width: 100%;
    height: 100%;
    min-height: 100%;  
    padding:0;
    margin: 0;
    background-color: var(--colour-secondary);
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;  
    transition: all 0.2s ease;    
    background-repeat: no-repeat;
    background-size: 100%;         
}
.zoomPage{
    overflow: hidden;
}

.blur {
    filter: blur($blur-factor);  
    transition: all 0.2s ease;
    overflow: hidden;
}

*, :after, :before {
    box-sizing: border-box;
}
a {
    text-decoration: none;
}
textarea {
    width: 100%;
    max-width: 100%;
  }

  #nprogress .bar {
      background: #ff3fb4 !important;
  }

@media only screen and (min-width: 1080px) {
    .zoomPage {
        overflow-y: auto;
    }
}