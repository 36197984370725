@mixin animate($value){
  -webkit-animation: $value;
  -moz-animation:    $value;
  -o-animation:      $value;
  animation:         $value;
}

@mixin transform($property){
    -webkit-transform: $property;
    -ms-transform: $property; 
    transform: $property; 
}