@import "./theme-variables.scss";

$changeProductModalBodyWidth: 232px;
$changeProductModalBodyWidthDesktop: 525px;

$changeProductModalBodyContentPaddingTop: 29px;
$changeProductModalBodyContentPaddingRight: 16px;
$changeProductModalBodyContentPaddingBottom: 12px;
$changeProductModalBodyContentPaddingLeft: 16px;
$changeProductModalBodyContentMarginBottomDesktop: 80px;

$changeProductModalBodyTextPaddingBottom: 18px;
$changeProductModalBodyLineHeight: 24px;

$changeProductModalBodyButtonHeight: 36px;
$changeProductModalBodyButtonTextSpacing: 0.69px;

$changeProductModalCloseButtonSize: 32px;

$changeProductModalContentPadding: 16px;

$changeProductDivisionPaddingBottom: 20px;
$changeProductDivisionTitleFontSize: 13px;
$changeProductDivisionTitleLetterSpacing: 2.17px;
$changeProductDivisionTitlePaddingBottom: 2px;

.change-product-container {
    width: $changeProductModalBodyWidth;
    height: 100%;
    position: fixed;
    top: $navbar-height-mobile;
    right: -$changeProductModalBodyWidth;
    z-index: 19;
}

.change-product-visible {
    animation: slidein 0.25s linear;
    transition: all 0.25s ease; 
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}

.change-product-hidden {
    animation: slideout 0.25s linear;
    transition: all 0.25s ease; 
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}

.change-product-modal {
    width: 100%;
    height: 100%;
    background-color: var(--colour-tertiary);
    color: var(--text-colour-primary);
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'FoundersGrotesk', sans-serif;
}

.change-product-close {
    width: 100%;
    height: $changeProductModalCloseButtonSize;
    position: absolute;
    top: 0;
    left: 0;
}

.change-product-modal-close-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $changeProductModalCloseButtonSize;
    height: $changeProductModalCloseButtonSize;
    top: 0;
    right: 0;
    cursor: pointer;
    text-align: center;
    z-index: 20;
}

.change-product-modal-content-container {
    width: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: $changeProductModalCloseButtonSize;
    left: 0;
}

.change-product-content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    position: relative;
    top: unset;
    left: unset;
    padding: 0 $changeProductModalContentPadding $changeProductModalContentPadding $changeProductModalContentPadding;
    margin-bottom: $changeProductModalCloseButtonSize;
}

.change-product-division {
    width: 100%;
    padding-bottom: $changeProductDivisionPaddingBottom;
}

.change-product-division-title {
    font-family: 'FeniceStd', sans-serif;
    font-size: $changeProductDivisionTitleFontSize;
    letter-spacing: $changeProductDivisionTitleLetterSpacing;
    color: var(--text-colour-primary);
    padding-bottom: $changeProductDivisionTitlePaddingBottom;
    text-transform: uppercase;
}

.change-product-division-products {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
}

@media screen and (min-width: 1080px) {
    .change-product-container {
        width: $changeProductModalBodyWidthDesktop;
        right: -$changeProductModalBodyWidthDesktop;
        top: $navbar-height-desktop;
    }

    .change-product-visible {
        animation: slideindesktop 0.25s linear;
        transition: all 0.25s ease; 
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
    }

    .change-product-hidden {
        animation: slideoutdesktop 0.25s linear;
        transition: all 0.25s ease; 
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
    }

    .change-product-close {
        height: ($changeProductModalCloseButtonSize * 1.5);
    }

    .change-product-modal-close-button {
        width: ($changeProductModalCloseButtonSize * 1.5);
        height: ($changeProductModalCloseButtonSize * 1.5);
    }

    .change-product-content {
        top: ($changeProductModalCloseButtonSize * 1.5);
        padding: 0 ($changeProductModalContentPadding * 1.5) ($changeProductModalContentPadding * 1.5) ($changeProductModalContentPadding * 1.5);
        margin-bottom: $changeProductModalBodyContentMarginBottomDesktop;
    }

    .change-product-division {
        padding-bottom: ($changeProductDivisionPaddingBottom * 1.5);
    }

    .change-product-division-title {
        font-size: ($changeProductDivisionTitleFontSize * 1.5);
        letter-spacing: ($changeProductDivisionTitleLetterSpacing * 1.5);
        padding-bottom: ($changeProductDivisionTitlePaddingBottom * 1.5);
    }
}

@keyframes slidein {
    0% {
        right: -$changeProductModalBodyWidth;
    }

    100% {
        right: 0;
    }
}

@keyframes slideout {
    0% {
        right: 0
    }

    100% {
        right: -$changeProductModalBodyWidth;
    }
}

@keyframes slideindesktop {
    0% {
        right: -$changeProductModalBodyWidthDesktop;
    }

    100% {
        right: 0;
    }
}

@keyframes slideoutdesktop {
    0% {
        right: 0
    }

    100% {
        right: -$changeProductModalBodyWidthDesktop;
    }
}