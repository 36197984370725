//Theme variables

$maximum-page-width: 1920px;
$container-padding: 30px;
$container-padding-mobile: 20px;
$navbar-height-mobile: 64px;
$navbar-height-desktop: 96px;

//Configure state carousel variables

$carousel-item-padding: 0.2rem;
$carousel-handle-container-width: 3rem;
$levelOne-carousel-height: 136px;
$options-carousel-height: 112px;
$options-carousel-padding: 8px;
$suboptions-carousel-height: 104px;
$levelOne-carousel-padding: 8px;
$suboptions-carousel-padding: 8px;
$options-carousel-height-no-text: 88px;
$options-carousel-padding-no-text: 10px;
$suboptions-carousel-height-no-text: 80px;
$suboptions-carousel-padding-no-text: 10px;

//Buttons
$buttonContainerHeightMobile: 56px;
$buttonContainerHeightDesktop: 84px;
$buttonHeightDesktop: 60px;
$buttonHeightMobile: 40px;

$blur-factor: 10px;
