@import "./theme-variables.scss";

.darken-filter-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: $navbar-height-mobile;
    left: 0;
    z-index: 18;
    background-color: black;
    opacity: 0.25;
}

@media only screen and (min-width: 1024px) {
    .darken-filter-overlay {
        top: $navbar-height-desktop;
    }
}