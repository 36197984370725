@import "./theme-variables.scss";

.screensaver-modal-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
}

.screensaver-modal-grid {
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: grid;
    grid-template-columns: auto 416px auto;
    grid-template-rows: auto 176px auto;
    grid-template-areas:
        "header header header"
        "leftside body rightside"
        "footer footer footer";
}

.screensaver-modal-grid-header {
    grid-area: header;
    background-color: black;
    opacity: 0.25;
}

.screensaver-modal-grid-left-side {
    grid-area: leftside;
    background-color: black;
    opacity: 0.25;
}

.screensaver-modal-grid-body {
    grid-area: body;
    background-color: var(--colour-tertiary);
    color: var(--colour-primary);
    box-shadow: -7px 13px 38px #00000029;
    display: flex;
    flex-direction: column;
    font-family: 'FoundersGrotesk', sans-serif;
}

.screensaver-modal-grid-body-content {
    width: 100%;
    height: 100%;
    padding-top: 30px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 26px;
}

.screensaver-modal-grid-body-text {
    font-size: 24px;
    width: 100%;
    text-align: left;
    padding-bottom: 60px;
}

.screensaver-modal-grid-body-button {
    width: 100%;
    text-align: right;
    font-size: 21px;
}

.screensaver-modal-grid-right-side {
    grid-area: rightside;
    background-color: black;
    opacity: 0.25;
}

.screensaver-modal-grid-footer {
    grid-area: footer;
    background-color: black;
    opacity: 0.25;
}