$themes: (
    light: (
        'colour-primary': #041E3A,
        'colour-secondary':#EFEFEF,
        'colour-tertiary': #FFFFFF,
        'colour-box-shadow': #00000029,
        'colour-selected': #041E3A,
        'navbar-color':#041E3A,
        'navigation-drawer-background': #FFFFFF,
        'navigation-drawer-border': inherit,
        'basket-item-background': #FFFFFF,
        'basket-item-expand-icon-filter': none,
        'load-from': #EFEFEF,
        'load-to-banner': darken(#EFEFEF, 6%),
        'load-to': darken(#EFEFEF, 4%),
        'load-from-navy': lighten(#041E3A, 6%),
        'load-to-navy': #041E3A,
        'text-colour-primary': #041E3A,
        'text-colour-navbar': #FFFFFF,
        'button-border': none,
        'button-text-colour': #FFFFFF,
        'cancel-button-background': #EFEFEF,
        'cancel-button-text-colour': #041E3A,
        'colour-swatch-background': #FFFFFF,
        'input-border-colour': #041E3A,
        'input-autofill-box-shadow': #00000000,
        'radio-input-border-colour': #041E3A,
        'radio-input-background-colour': #041E3A,
        'snackbar-background': #323232,
        'snackbar-text': #EFEFEF,
        'pdp-edit-button-filter': unset
    ),
    dark: (
        'colour-primary': #000000,
        'colour-secondary':#000000,
        'colour-tertiary': #FFFFFF,
        'colour-box-shadow': #00000029,
        'colour-selected': #FFFFFF,
        'navbar-color':#000000,
        'navigation-drawer-background': rgba(0, 0, 0, 0.55),
        'navigation-drawer-border': #6f6f6f,
        'basket-item-background': rgba(63, 63, 63, 0.75),
        'basket-item-expand-icon-filter': invert(100%),
        'load-from': #8b8b8b,
        'load-to-banner': darken(#242424, 6%),
        'load-to': rgba(0, 0, 0, 0),
        'load-from-navy': lighten(#041E3A, 6%),
        'load-to-navy': #041E3A,
        'text-colour-primary': #FFFFFF,
        'text-colour-navbar': #FFFFFF,
        'button-border': 1px solid #FFFFFF,
        'button-text-colour': #FFFFFF,
        'cancel-button-background': #FFFFFF,
        'cancel-button-text-colour': #000000,
        'colour-swatch-background': #000000,
        'input-border-colour': #FFFFFF,
        'input-autofill-box-shadow': #000000,
        'radio-input-border-colour': #FFFFFF,
        'radio-input-background-colour': #FFFFFF,
        'snackbar-background': #FFFFFF,
        'snackbar-text': #000000,
        'pdp-edit-button-filter': invert(100%)
    )
);

@each $name, $map in $themes {
    .theme-#{$name} {
        @each $key, $value in $map {
            --#{$key}: #{$value};
        }
    }
}