@import "./theme-variables.scss";

$navigationDrawerWidthMobile: 232px;
$navigationDrawerWidthDesktop: 525px;

$navigationDrawerLinksPaddingLeft: 16px;
$navigationDrawerLinksPaddingBottom: 40px;

$navigationDrawerLinkFontSize: 14px;
$navigationDrawerLinkPaddingTop: 32px;
$navigationDrawerLinkLineHeight: 24px;

$navigationDrawerLinksTopPaddingBottom: 24px;
$navigationDrawerLinkTopPaddingTop: 18px;

.navigation-drawer-container {
    position: absolute;
    width: $navigationDrawerWidthMobile;
    top: $navbar-height-mobile;
    left: -$navigationDrawerWidthMobile;
    z-index: 19;
    background-color: var(--navigation-drawer-background);
}

.navigation-drawer {
    width: 100%;
    height: 100%;
    color: var(--text-colour-primary);
    display: flex;
    flex-direction: column;
    font-family: 'FoundersGrotesk', sans-serif;
    overflow-y: scroll;
    flex-shrink: 0;
}

.navigation-drawer hr {
    width: 100%;
    border-color: var(--navigation-drawer-border);
}

.navigation-drawer-visible {
    animation: slidein 0.25s linear;
    transition: all 0.25s ease; 
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}

.navigation-drawer-hidden {
    animation: slideout 0.25s linear;
    transition: all 0.25s ease; 
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}

.navigation-drawer-links-top {
    display: flex;
    flex-direction: column;
    padding-left: $navigationDrawerLinksPaddingLeft;
    padding-bottom: $navigationDrawerLinksTopPaddingBottom;
    flex-shrink:0;
}

.navigation-drawer-link-top {
    font-family: 'FoundersGrotesk', sans-serif;
    font-size: $navigationDrawerLinkFontSize;
    color: var(--text-colour-primary);
    text-decoration: underline;
    text-align: left;
    line-height: $navigationDrawerLinkLineHeight;
    padding-top: $navigationDrawerLinkTopPaddingTop;
}

.navigation-drawer-links {
    display: flex;
    flex-direction: column;
    padding-left: $navigationDrawerLinksPaddingLeft;
}

.navigation-drawer-link {
    font-family: 'FoundersGrotesk', sans-serif;
    font-size: $navigationDrawerLinkFontSize;
    color: var(--text-colour-primary);
    text-decoration: underline;
    text-align: left;
    line-height: $navigationDrawerLinkLineHeight;
    padding-top: $navigationDrawerLinkPaddingTop;
}

.navigation-drawer-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}
.navigation-drawer-settings-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
}

.navigation-drawer-shield {
    position: absolute;
    width: $navigationDrawerWidthMobile;
    top: $navbar-height-mobile;
    left: -$navigationDrawerWidthMobile;
    z-index: 20;
    background-color: var(--colour-secondary);
}

@media screen and (min-width: 1080px) {
    .navigation-drawer-container {
        width: $navigationDrawerWidthDesktop;
        top: ($navbar-height-mobile * 1.5);
        left: -$navigationDrawerWidthDesktop;
    }

    .navigation-drawer-visible {
        animation: slideindesktop 0.25s linear;
        transition: all 0.25s ease; 
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
    }

    .navigation-drawer-hidden {
        animation: slideoutdesktop 0.25s linear;
        transition: all 0.25s ease; 
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
    }

    .navigation-drawer-links-top {
        padding-left: ($navigationDrawerLinksPaddingLeft * 1.5);
        padding-bottom: ($navigationDrawerLinksTopPaddingBottom * 1.5);
    }
    
    .navigation-drawer-link-top {
        font-size: ($navigationDrawerLinkFontSize * 1.5);
        line-height: ($navigationDrawerLinkLineHeight * 1.5);
        padding-top: ($navigationDrawerLinkTopPaddingTop * 1.5);
    }

    .navigation-drawer-links {
        padding-left: ($navigationDrawerLinksPaddingLeft * 1.5);
    }

    .navigation-drawer-link {
        font-size: ($navigationDrawerLinkFontSize * 1.5);
        line-height: ($navigationDrawerLinkLineHeight * 1.5);
        padding-top: ($navigationDrawerLinkPaddingTop * 1.5);
    }

    .navigation-drawer-settings-button {
        width: 60px;
        height: 60px;
    }

    .navigation-drawer-shield {
        width: $navigationDrawerWidthDesktop;
        top: ($navbar-height-mobile * 1.5);
        left: -$navigationDrawerWidthDesktop;
    }
}

@keyframes slidein {
    0% {
        left: -$navigationDrawerWidthMobile;
    }

    100% {
        left: 0;
    }
}

@keyframes slideout {
    0% {
        left: 0
    }

    100% {
        left: -$navigationDrawerWidthMobile;
    }
}

@keyframes slideindesktop {
    0% {
        left: -$navigationDrawerWidthDesktop;
    }

    100% {
        left: 0;
    }
}

@keyframes slideoutdesktop {
    0% {
        left: 0
    }

    100% {
        left: -$navigationDrawerWidthDesktop;
    }
}