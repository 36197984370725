@import "./theme-variables.scss";
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    padding: 16px;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: var(--colour-primary);
    color: var(--text-colour-navbar);
    z-index: 18;
}

.navbar-hamburger_container {
    place-self: center;
    height: 32px;
    margin-right: 16px;
}

.navbar-logo_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 32px;
    justify-content: center;
}

.navbar-logo {
    width: 100%;
    font-size: 21px;
    cursor: pointer;
    font-family: 'FeniceStd', sans-serif;
    font-weight: 400;
    letter-spacing: 1.89px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.navbar-localisation_container {
    place-self: center;
    position: relative;
    height: 32px;
}

.navbar-cart_container {
    place-self: center;
    position: relative;
    height: 32px;
}

.navbar-item {
    fill: var(--colour-secondary);
    cursor: pointer;
    width: 32px;
}

.dismiss-localisation-modal-element {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 17;
}

@media only screen and (max-width: 359px) {
    .navbar {
        width: 100%;
        height: 96px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

@media only screen and (max-width: 400px) {
    .navbar-logo {
        text-align: left;
    }
}

@media only screen and (min-width: 1080px) {
    .navbar {
        height: 96px;
        padding: 24px;
    }

    .navbar-hamburger_container {
        height: 48px;
        margin-right: 32px;
    }

    .navbar-logo_container {
        height: 48px;
    }

    .navbar-localisation_container {
        height: 48px;
    }

    .navbar-cart_container {
        height: 48px;
    }

    .navbar-logo {
        font-size: 31.5px;
        letter-spacing: 6px;
    }

    .navbar-item {
        width: 48px;
    }
}