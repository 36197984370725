@import "./theme-variables.scss";

$localisationModalContainerTop: 36px;
$localisationModalContainerWidth: 148px;
$localisationModalPaddingTop: 8px;
$localisationModalPaddingBottom: 8px;
$localisationModalOptionHeight: 48px;
$localisationModalOptionSelectedWidth: 72px;
$localisationModalOptionSelectedTickSize: 24px;
$localisationModalOptionNameFontSize: 14px;

.localisation-modal-container {
    min-width: $localisationModalContainerWidth;
    max-width: 85vw;
    height: fit-content;
    position: absolute;
    top: $localisationModalContainerTop;
    right: 0;
    background-color: var(--colour-tertiary);
    color: var(--colour-primary);
    z-index: 20;
}

.localisation-modal {
    width: 100%;
    height: 100%;
    padding-top: $localisationModalPaddingTop;
    padding-bottom: $localisationModalPaddingBottom;
    overflow-y: auto;
}

.localisation-modal-option {
    width: 100%;
    height: $localisationModalOptionHeight;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.localisation-modal-option-selected {
    width: $localisationModalOptionSelectedWidth;
    display: flex;
    align-items: center;
    justify-content: center;
}

.localisation-modal-option-selected-tick {
    width: $localisationModalOptionSelectedTickSize;
    height: $localisationModalOptionSelectedTickSize;
}

.localisation-modal-option-name {
    display: flex;
    align-items: center;
    width: auto;
    font-family: "FoundersGrotesk", sans-serif;
    font-size: $localisationModalOptionNameFontSize;
    color: var(--colour-primary);
}

@media screen and (min-width: 1080px) {
    .localisation-modal-container {
        min-width: ($localisationModalContainerWidth * 1.5);
        top: ($localisationModalContainerTop * 1.5);
    }

    .localisation-modal {
        padding-top: ($localisationModalPaddingTop * 1.5);
        padding-bottom: ($localisationModalPaddingBottom * 1.5);
    }

    .localisation-modal-option {
        height: ($localisationModalOptionHeight * 1.5);
    }

    .localisation-modal-option-selected {
        width: ($localisationModalOptionSelectedWidth * 1.5);
    }

    .localisation-modal-option-selected-tick {
        width: ($localisationModalOptionSelectedTickSize * 1.5);
        height: ($localisationModalOptionSelectedTickSize * 1.5);
    }

    .localisation-modal-option-name {
        font-size: ($localisationModalOptionNameFontSize * 1.5);
    }
}
