@import "./theme-variables";

.dev-password-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    color: #fff;
    overflow: hidden;
    visibility: visible;
    opacity: 1;
    transition: all 0.5s ease-in-out;
    z-index: 1000;
    height: 100%;
    width: 100%;
    font-family: "FoundersGrotesk", sans-serif;
}

.dev-password-container {
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
}

.dev-password-heading {
    text-align: center;
    color: white;
}

.dev-password-text {
    text-align: center;
    font-size: 1.4rem;
}

.dev-password-input {
    width: 50%;
}

.dev-password-input > input {
    background: none;
    border: none;
    border-bottom: 1px solid white;
    display: block;
    padding: 1rem 0rem;
    width: 100%;
    margin-bottom: 2rem;
    color: white;
    outline: none;
    font-size: 16px;
}

.input-error {
    color: red;
    font-size: 1.4rem;
}

.dev-password-container > button {
    padding: 1rem 4rem;
    border: 1px solid var(--colour-secondary);
    background-color: var(--colour-secondary);
    color: var(--colour-primary);
    font-weight: bold;
    align-self: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    cursor: pointer;
    font-size: 1.2rem;
    font-family: "FoundersGrotesk", sans-serif;
}

.dev-password-container > button:hover {
    background-color: var(--colour-primary);
    color: var(--colour-tertiary);
    border: 1px solid var(--colour-secondary);
}