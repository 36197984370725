@import "./theme-variables.scss";

.darken-filter-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 18;
    background-color: black;
    opacity: 0.25;
}

.hidden-settings-modal-overlay {
    width: 100vw;
    height: 100vh;
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.25;
}

.hidden-settings-modal-container {
    position: fixed;
    top: 15%;
    left: 20%;
    width: 60%;
    height: 60%;
    background: var(--colour-tertiary);
    z-index: 20;
    
}

.hidden-settings-modal {
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-family: "FoundersGrotesk", "sans-serif";
    color: var(--colour-primary);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.hidden-settings-modal-top-bar {
    width: 100%;
    background-color: var(--colour-primary);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.hidden-settings-modal-header {
    color: var(--colour-secondary);
    font-size: 25px;
    margin-left: 1rem;
}

.close-modal-icon {
    color: var(--colour-primary);
    background-color: var(--colour-primary);
    width: 4rem;
    height: 4rem;
    cursor: pointer;
}

.hidden-settings-modal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--colour-tertiary);
}


.setting-line {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    outline: none;
}

.setting-label {
    font-size: 1.5rem;
    margin-right: 1rem;
    flex-shrink: 1;
    width: 15rem;
    text-align: left;
}

.checkbox-container {
    display: block;
    position: relative;
    padding-left: 45px;
    margin-bottom: 15px;
    top: 6px;
    cursor: pointer;
    font-size: 20px;
    width: 3rem;
    height: 3rem;
    z-index: 200;
    outline: none;
    outline-color: transparent;
    outline-width: 0;
}

.setting-checkbox {
    position: relative;
    right: 2rem;
    outline: none;
    visibility: hidden;

}

.checkbox-content {
    position: absolute;
    top: 0;
    left: 0;
    height: 3rem;
    width: 3rem;
    background-color: var(--colour-secondary);
    border: 1px solid var(--colour-primary);
    outline: none;
    outline-color: transparent;
    box-shadow: none;
    outline-width: 0;
}

.checkbox-container input:checked ~ .checkbox-content {
    background-color: var(--colour-primary);
}

.checkbox-content::after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked ~ .checkbox-content::after {
    display: block;
}

.checkbox-container .checkbox-content::after {
    left: 15px;
    bottom: 9px;
    width: 18px;
    height: 36px;
    border: solid white;
    border-width: 0 4px 4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.modal-ref {
    min-width: 15vw;
    max-width: 85vw;
    height: 5vh;
    position: absolute;
    top: 5%;
    right: 0;
    background-color: var(--colour-tertiary);
    color: var(--colour-primary);
    z-index: 20;
}